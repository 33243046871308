.sidebar {
  height: 100%;
  width: 200px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  padding-top: 27px;
  padding-left: 27px;
  padding-right: 27px;
  font-family: Arial, Verdana, sans-serif;
  line-height: 20px;
  text-align: left;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  width: 2px;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: #2f3542;
}
.sidebar::-webkit-scrollbar-track {
  background-color: lightgrey;
}

.spacer {
  font-size: 1px;
  line-height: 1px;
}

a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
}

.menu-title {
  color: black;
  font-weight: bold;
  font-size: 16px;
  line-height: 40px;
}

.menu-active {
  padding-top: 0px;
  color: black;
  font-weight: bold;
  font-size: 15px;
  line-height: 15px;
  margin-bottom: -5px;
}

.menu-common {
  padding-top: 0px;
  color: black;
  font-size: 15px;
  line-height: 15px;
  margin-bottom: -5px;
}

.menu-copyright {
  padding-top: 50px;
  color: black;
  font-size: 15px;
}